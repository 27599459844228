<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Planillas Redireccionamiento</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Hidrocarburos</li>
                  <li class="breadcrumb-item active">Operación</li>
                  <li class="breadcrumb-item active">Procesos</li>
                  <li class="breadcrumb-item active">
                    Planillas Redireccionamiento
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-12">
                    <!-- Botones Iniciales -->
                    <div class="btn-group shadow float-right">
                      <button
                        type="button"
                        class="btn bg-gradient-primary"
                        style="cursor: pointer"
                        @click="create()"
                        v-if="
                          $store.getters.can(
                            'hidrocarburos.planillaRedireccionamiento.create'
                          )
                        "
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                      <button
                        type="button"
                        class="btn bg-navy"
                        @click="getIndex()"
                      >
                        <i class="fas fa-sync-alt fa-spin"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body p-0">
                <table
                  class="table table-bordered table-striped table-hover table-sm shadow-sm"
                >
                  <thead>
                    <tr>
                      <th></th>
                      <th class="text-center">
                        <label>Empresa</label>
                        <v-select
                          class="form-control form-control-sm p-0"
                          v-model="empresa"
                          placeholder="Empresa"
                          label="razon_social"
                          :options="listasForms.empresas"
                          @input="selectEmpresa()"
                          :class="[
                            $store.getters.getDarkMode ? 'dark-vselect' : '',
                          ]"
                        ></v-select>
                      </th>
                      <th class="text-center">
                        <label>Fecha Inicial</label>
                        <input
                          type="date"
                          v-model="filtros.fecha_ini"
                          label="id"
                          class="form-control form-control-sm shadow-sm"
                        />
                      </th>
                      <th class="text-center">
                        <label>Fecha Final</label>
                        <input
                          type="date"
                          v-model="filtros.fecha_fin"
                          label="id"
                          class="form-control form-control-sm shadow-sm"
                        />
                      </th>
                      <th class="text-center">
                        <label>Estado</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th class="text-center p-3">
                        <label>Cant Firmantes</label>
                      </th>
                      <th class="text-center p-3"><label>Firmados</label></th>
                      <th class="text-center p-3"><label>Acciones</label></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="planilla in planillas.data" :key="planilla.id">
                      <td>
                        <a
                          :href="uri_docs + planilla.empresa.logo"
                          target="_blank"
                        >
                          <img
                            :src="uri_docs + planilla.empresa.logo"
                            alt="Logo"
                            width="75"
                            class="rounded mx-auto d-block"
                          />
                        </a>
                      </td>
                      <td class="text-center">
                        {{ planilla.empresa.razon_social }}
                      </td>
                      <td class="text-center">
                        {{ planilla.fecha_inicial }}
                      </td>
                      <td class="text-center">
                        {{ planilla.fecha_final }}
                      </td>
                      <td class="text-center">
                        <span
                          :class="
                            planilla.estado == 1
                              ? 'badge bg-warning'
                              : planilla.estado == 2
                              ? 'badge bg-success'
                              : planilla.estado == 3
                              ? 'badge bg-purple'
                              : ''
                          "
                        >
                          {{ planilla.nEstado }}</span
                        >
                      </td>
                      <td class="text-center">
                        <span class="badge bg-primary">
                          {{ listasForms.firmantes.length }}</span
                        >
                      </td>
                      <td
                        class="text-center"
                        v-if="
                          planilla.planilla_firmas &&
                            planilla.planilla_firmas.length > 0
                        "
                      >
                        <div
                          v-for="(firma, k) in planilla.planilla_firmas"
                          :key="k"
                        >
                          <span class="badge bg-navy text-nowrap">
                            {{ k + 1 }}.{{ firma.user.name }}</span
                          >
                        </div>
                      </td>
                      <td class="text-center" v-else>
                        <span class="badge bg-warning text-nowrap">
                          Sin Firmas</span
                        >
                      </td>
                      <td class="text-center">
                        <button
                          type="button"
                          class="btn btn-sm"
                          :class="
                            listasForms.firmantes.length ==
                            planilla.planilla_firmas.length
                              ? 'alert-default-success'
                              : 'alert-default-info'
                          "
                          title="Ver PDF"
                          @click="verPDF(planilla.id)"
                          v-if="
                            [2, 3].includes(planilla.estado) &&
                              $store.getters.can(
                                'hidrocarburos.planillaRedireccionamiento.print'
                              )
                          "
                        >
                          <i class="fas fa-print"></i>
                        </button>
                        <!-- Botón Firmas -->
                        <button
                          type="button"
                          class="btn btn-sm"
                          :class="
                            listasForms.firmantes.length ==
                            planilla.planilla_firmas.length
                              ? 'alert-default-success'
                              : 'alert-default-dark'
                          "
                          data-toggle="modal"
                          data-target="#Modal_firmas"
                          title="Firmar Planilla"
                          @click="
                            $refs.planillaRedireccionamientoFirmas.getDataFirmas(
                              planilla
                            )
                          "
                          v-if="
                            [2, 3].includes(planilla.estado) &&
                              $store.getters.can(
                                'hidrocarburos.planillaRedireccionamiento.firmar'
                              )
                          "
                        >
                          <i class="fas fa-signature"></i>
                        </button>
                        <button
                          type="button"
                          class="btn btn-sm alert-default-primary"
                          title="Editar Planilla "
                          @click="edit(planilla)"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.planillaRedireccionamiento.edit'
                            )
                          "
                        >
                          <i class="fas fa-edit"></i>
                        </button>
                        <!--  <button
                          type="button"
                          class="btn btn-sm alert-default-danger"
                          v-if="
                            planilla.estado == 1 &&
                              $store.getters.can(
                                'hidrocarburos.planillaRedireccionamiento.delete'
                              )
                          "
                          title="Eliminar"                          
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button> -->
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="planillas.total">
                  <p>
                    Mostrando del <b>{{ planillas.from }}</b> al
                    <b>{{ planillas.to }}</b> de un total:
                    <b>{{ planillas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  class="float-right shadow"
                  :data="planillas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <PlanillaRedireccionamientoFirmas
      ref="planillaRedireccionamientoFirmas"
    ></PlanillaRedireccionamientoFirmas>
  </div>
</template>
<script>
import pagination from "laravel-vue-pagination";
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import PlanillaRedireccionamientoFirmas from "./PlanillaRedireccionamientoFirmas";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "PlanillaRedireccionamientoIndex",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    Loading,
    pagination,
    vSelect,
    PlanillaRedireccionamientoFirmas,
  },
  data() {
    return {
      cargando: false,
      filtros: {
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
      },
      listasForms: {
        empresas: [],
        usuarios: [],
        firmantes: [],
        estados: [],
      },
      empresa: {},
      planillas: {},
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },

  methods: {
    async getIndex(page = 1) {
      this.cargando = true;
      await axios
        .get("/api/hidrocarburos/planillasRedireccionamiento?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.planillas = response.data;
        });
      this.cargando = false;
    },

    async getEmpresas() {
      await axios
        .get("/api/admin/empresas/lista", {
          params: {
            linea_negocio_id: 2,
          },
        })
        .then((response) => {
          this.listasForms.empresas = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/205").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    async getUsers() {
      await axios
        .get("/api/admin/funcionarios/lista", {
          params: {
            frontera: true,
          },
        })
        .then((response) => {
          this.listasForms.usuarios = response.data;
        });
    },

    async getFirmantes() {
      await axios.get("/api/lista/206").then((response) => {
        this.listasForms.firmantes = response.data;
      });
    },

    selectEmpresa() {
      this.filtros.empresa_id = null;
      if (this.empresa) {
        this.filtros.empresa_id = this.empresa.id;
      }
    },

    create() {
      return this.$router.push({
        name: "/Hidrocarburos/PlanillasRedireccionamientoForm",
        params: { accion: "Crear", id: 0 },
      });
    },

    edit(planilla) {
      return this.$router.push({
        name: "/Hidrocarburos/PlanillasRedireccionamientoForm",
        params: {
          accion: "Editar",
          data_edit: planilla,
          id: planilla.id,
        },
      });
    },

    verPDF(planilla) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/hidrocarburos/planillasRedireccionamiento/pdf/" + planilla,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },
  },

  async mounted() {
    await this.getIndex();
    await this.getEmpresas();
    await this.getEstados();
    await this.getUsers();
    await this.getFirmantes();
  },
};
</script>
