<template>
  <div class="hold-transition">
    <div class="modal fade" id="Modal_firmas">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Aprobación Planilla Redireccionamiento
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              ref="closeModalFirmas"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div
                class="col-md-4"
                v-for="(firmante, index) in formFirmas"
                :key="index"
              >
                <div class="card card-widget widget-user shadow">
                  <div class="widget-user-bg-header bg-frontera text-white">
                    <div class="p-2" v-if="firmante.firma != undefined">
                      {{ firmante.firma.user.name.toUpperCase() }}
                    </div>
                    <div class="p-2">
                      {{ firmante.tipo_firma.descripcion }}
                    </div>
                  </div>
                  <div class="widget-user-link_pho">
                    <a target="_blank">
                      <img
                        v-if="firmante.firma == undefined"
                        class="img-fluid img-circle elevation-2"
                        src="/img/perfil_vacio.png"
                        style="height: 128px; width: 128px"
                        alt="User profile picture"
                      />
                      <img
                        v-else
                        class="profile-user-img img-fluid img-circle elevation-2"
                        :src="
                          firmante.firma.user.link_photography
                            ? uri_docs + firmante.firma.user.link_photography
                            : '/img/perfil_vacio.png'
                        "
                        style="height: 128px; width: 128px"
                        alt="User profile picture"
                        :href="uri_docs + firmante.firma.user.link_photography"
                      />
                    </a>
                  </div>
                  <div class="card-body pt-5 mt-5">
                    <!-- Usuario para firmar -->
                    <div
                      class="row"
                      v-if="
                        !firmante.firma &&
                          firmante.users.some(
                            (u) => u.user_id === $store.state.user.id
                          ) &&
                          $store.state.user.link_firma
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning">
                          <small
                            ><strong>
                              {{
                                $store.getters.getAuthAzure
                                  ? `La sesión esta iniciada con Azure.`
                                  : `Para aprobar el servicio, por favor digite su clave.`
                              }}
                            </strong></small
                          >
                        </div>
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <label for="sign_password">Contraseña</label>
                        <input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="form.pass"
                          :class="
                            $v.form.pass.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        />
                      </div>
                      <div
                        class="form-group col-md-6"
                        v-if="!$store.getters.getAuthAzure"
                      >
                        <label for="re_sign_password"
                          >Confirme Contraseña</label
                        >
                        <input
                          type="password"
                          class="form-control form-control-sm"
                          v-model="form.re_pass"
                          :class="
                            $v.form.re_pass.$invalid ? 'is-invalid' : 'is-valid'
                          "
                        />
                      </div>
                      <div class="form-group col-md-12">
                        <label for="observaciones">Observación</label>
                        <textarea
                          vtype="date"
                          rows="2"
                          class="form-control form-control-sm"
                          v-model="form.observacion"
                          :class="
                            $v.form.observacion.$invalid
                              ? 'is-invalid'
                              : 'is-valid'
                          "
                        ></textarea>
                      </div>
                      <div class="form-group col-md-12">
                        <div class="justify-content-center">
                          <button
                            type="button"
                            title="Guardar firma"
                            data-toggle="tooltip"
                            class="btn btn-sm bg-success"
                            @click="save(firmante.tipo_firma.numeracion)"
                            v-if="!$v.form.$invalid"
                          >
                            <i class="fas fa-signature"></i>
                            <div>
                              <span><small>Aprobar Servicio</small></span>
                            </div>
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- Si no tiene firma asociada el usuario -->
                    <div
                      class="row"
                      v-else-if="
                        !$store.state.user.link_firma &&
                          firmante.users.some(
                            (u) => u.user_id === $store.state.user.id
                          )
                      "
                    >
                      <div class="form-group col-md-12">
                        <div class="callout callout-warning text-center">
                          <small
                            ><strong>
                              <i>Para firmar debe tener una firma digital</i>
                            </strong></small
                          >
                        </div>
                      </div>
                    </div>
                    <div class="row" v-else-if="firmante.firma">
                      <div class="col-md-12">
                        <b v-if="firmante.firma.user.link_firma">
                          <img
                            class="img-fluid border border-dark"
                            :src="uri_docs + firmante.firma.user.link_firma"
                          />
                        </b>
                        <hr class="border border-dark" />
                        <div>
                          <b> Firmado Por: {{ firmante.firma.user.name }}</b>
                        </div>
                        <div>
                          <b> Firmado: {{ firmante.firma.fecha_firma }}</b>
                        </div>
                        <div>
                          <b>
                            Observación: {{ firmante.firma.observaciones }}</b
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              class="btn btn-sm bg-gradient-info"
              @click="$parent.verPDF(planilla.id)"
              v-if="
                $store.getters.can(
                  'hidrocarburos.planillaRedireccionamiento.pdf'
                )
              "
            >
              <i class="fas fa-print"> Imprimir Planilla</i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { required, sameAs } from "vuelidate/lib/validators";

export default {
  name: "PlanillaRedireccionamientoFirmas",
  data() {
    return {
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
      form: {},
      formFirmas: [],
      planilla: {},
    };
  },

  validations() {
    if (!this.$store.getters.getAuthAzure) {
      return {
        form: {
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          observacion: {
            required,
          },
        },
      };
    } else {
      return {
        form: {
          observacion: {
            required,
          },
        },
      };
    }
  },

  methods: {
    async getDataFirmas(planilla) {
      this.$parent.cargando = true;
      this.planilla = planilla;
      this.formFirmas = [];
      this.$parent.listasForms.firmantes.forEach((fir) => {
        // Se valida si ya exite firma
        let firma = this.planilla.planilla_firmas.find(
          (f) => f.tipo_firma == fir.numeracion
        );

        let users = this.$parent.listasForms.usuarios.filter(
          (u) => u.cargo == fir.cod_alterno
        );

        let tipo_firma = fir;

        this.formFirmas.push({
          tipo_firma,
          firma,
          users,
        });
        // Se valida la creacion de los datos del formulario para firmar
        if (!firma) {
          this.form = {
            id: null,
            tipo_firma: null,
            planilla_redireccionamiento_id: this.planilla.id,
            pass: null,
            re_pass: null,
            observacion: null,
          };
        }
      });
      this.$parent.cargando = false;
    },

    async llenarFirmar() {
      let cambio = [];
      let me = this;
      let arregloAgregar = [];
      me.formFirmas.forEach(async function(element, index) {
        if (me.firmas.length > 0) {
          me.firmas.forEach(async (firma) => {
            if (element.user_id == firma.user_id) {
              me.formFirmas[index].user = firma.user;
              me.formFirmas[index].user_id = firma.user_id;
              me.formFirmas[index].fecha_firma = firma.fecha_firma;
              me.formFirmas[index].observaciones = firma.observaciones;
            }
            if (!arregloAgregar.includes(element.user_id)) {
              arregloAgregar.push(element.user_id);
              cambio.push(element);
            }
          });
        } else {
          cambio.push(element);
        }
      });
      me.formFirmas = cambio;
    },

    save(tipo_firma) {
      let me = this;
      me.$parent.cargando = true;
      me.form.loginAzure = me.$store.getters.getAuthAzure;
      me.form.tipo_firma = tipo_firma;
      axios
        .post(
          "/api/hidrocarburos/planillasRedireccionamiento/saveFirma",
          me.form
        )
        .then((response) => {
          if (!response.data.user_error) {
            me.$parent.cargando = false;
            me.$parent.getIndex();
            me.$refs.closeModalFirmas.click();
            me.$swal({
              icon: "success",
              title: "Se Firmo Exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          } else {
            me.$parent.cargando = false;
            me.indexFirma = 0;
            me.$swal({
              icon: "error",
              title: response.data.user_error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo... " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          me.$parent.cargando = false;
        });
    },

    correoFirmasInd(firmante) {
      this.$parent.cargando = true;
      let data = {
        tif_liquidacion_id: firmante.tif_liquidacion_id,
        nombre_cargo: firmante.nombre_cargo,
        user_id: firmante.user_id,
        reenvio_fecha: moment().format("YYYY-MM-DD HH:mm:ss"),
        anti_reenvio_fecha: firmante.anti_reenvio_fecha,
        tif_cargo_operacion_id: firmante.tif_cargo_operacion_id,
        accion: 2,
      };
      axios
        .get("/api/tif/liquidaciones/enviarCorreoFirmas", {
          params: data,
        })
        .then((response) => {
          this.$parent.cargando = false;
          this.$parent.getIndex();
          this.$swal({
            icon: "success",
            title: response.data.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.$parent.cargando = false;
        });
    },
  },
};
</script>
<style>
.widget-user-link_pho {
  left: 50%;
  margin-left: -68px;
  position: absolute;
  top: 90px;
}
.widget-user-bg-header {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  height: 130px;
  padding: 1rem;
  text-align: center;
}
</style>
